//https://pixabay.com/photos/connection-hand-human-robot-touch-3308188/
import GenericHeader from "../components/GenericHeader";
import { PRIMARY, SECONDARY } from "../components/Colors";
import React, { useEffect } from "react";
import { isBefore, differenceInSeconds } from "date-fns";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ScoresTable from "../components/Tables";
import { useLocation } from "react-router-dom";

export default function Dashboard({ setDivHeight, data }) {
  const difference = differenceInSeconds(new Date(data.startDateTime), new Date(Date.now()));
  console.log(difference, new Date(data.startDateTime), new Date(Date.now()));
  const history = useLocation();
  useEffect(() => {
    document.querySelector("#some-container").scrollIntoView();
  }, [history]);
  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"Home"} setDivHeight={setDivHeight} />
      <div
        style={{
          backgroundColor: PRIMARY,
          height: 100,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        id={"some-container"}
      >
        <h3 className="headers" style={{ color: SECONDARY, textAlign: "center" }}>
          IEEE Region 8 Robot Championship Malta {data.year}
        </h3>
      </div>
      {/*<div style={{backgroundColor: PRIMARY}}>*/}
      {/*  <img src={logo} alt="Logo" height={100} />*/}
      {/*</div>*/}
      <br />
      <ScoresTable appData={data} showTop={true} headings={["id", "Overall Place", "Total Points"]} />
      {isBefore(Date.now(), new Date(data.startDateTime)) && (
        <div style={{ justifyContent: "center", display: "flex", marginTop: 10 }}>
          <CountdownCircleTimer isPlaying duration={difference} colors={["rgba(63,0,0, 0.8)"]} colorsTime={[0]}>
            {({ remainingTime }) => {
              // const seconds
              const days = Math.floor(remainingTime / 24 / 60 / 60);
              const daysSeconds = days * 24 * 60 * 60;
              const hours = Math.floor((remainingTime - daysSeconds) / 60 / 60);
              const hoursSecond = hours * 60 * 60;
              const minute = Math.floor((remainingTime - daysSeconds - hoursSecond) / 60);
              const minuteSeconds = minute * 60;
              const seconds = Math.floor(remainingTime - daysSeconds - hoursSecond - minuteSeconds);
              return (
                <p style={{ padding: 15 }}>
                  {days && days > 0 ? `${days} days` : ""} {hours && hours > 0 ? `${hours} hours` : ""} {(hours > 0 && minute) || minute > 0 ? `${minute} minutes` : ""}{" "}
                  {seconds > 0 || (hours > 0 && minute && minute > 0) ? `${seconds} seconds` : ""}
                </p>
              );
            }}
            {/*// format(new Date(remainingTime), "dd") + " days " +format(new Date(remainingTime), "HH:mm:ss")}*/}
          </CountdownCircleTimer>
          {/*<Countdown date={new Date("03-12-2022 18:00")} />*/}
        </div>
      )}
      <div className="container">
        <div style={{ padding: 10, paddingBottom: 20 }}>
          {data.registerLink && (
            <a href={data.ticketsLink} className={"button"}>
              BOOK YOUR TICKETS HERE
            </a>
          )}
        </div>
        <h3 className="headers">Robot Championship {data.year}</h3>
        <p>{data.about}</p>

        <div className="row space-around">
          <div className="center">
            <p className="center" style={{ color: PRIMARY, fontWeight: "bold" }}>
              {data.participants}
            </p>
            <p className="center">Participants</p>
          </div>
          <div className="center">
            <p className="center" style={{ color: PRIMARY, fontWeight: "bold" }}>
              {data.teams}
            </p>
            <p className="center">Teams</p>
          </div>
          <div className="center">
            <p className="center" style={{ color: PRIMARY, fontWeight: "bold" }}>
              {data.countries}
            </p>
            <p className="center">Countries</p>
          </div>
        </div>
        <div style={{ padding: 10, paddingBottom: 20 }}>
          {data.liveWatch && (
            <a href={data.liveYoutubeLink} className={"button"}>
              WATCH LIVE YOUTUBE
            </a>
          )}
        </div>
        <div style={{ padding: 10, paddingBottom: 20 }}>
          {data.registerLink && (
            <a href={data.registerLink} className={"button"}>
              REGISTER VOLUNTEER
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
