import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SECONDARY, PRIMARY } from "../components/Colors";
import GenericHeader from "../components/GenericHeader";
import { Person } from "@mui/icons-material";
import React, { useEffect } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export default function SpecificTeam({ width, setDivHeight }) {
  let location = useLocation();
  const navigate = useNavigate();
  const team = location.state.team;
  const path = team.photoPath;
  const year = window.location.pathname.split("/")[1] || 2024;
  const history = useLocation();
  useEffect(() => {
    document.querySelector("#some-container").scrollIntoView();
  }, [history]);

  let height = 300;
  if (width < 400) {
    height = 100;
  }
  const SupportButtons = () => {
    if (team.socialLink2) {
      let button1 = null;
      let button2 = null;
      if (team.socialLink2.toLowerCase().includes("fb") || team.socialLink2.toLowerCase().includes("facebook"))
        button1 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink2}>
              SUPPORT TEAM Facebook
            </a>
          </p>
        );
      else if (team.socialLink2.toLowerCase().includes("lin") || team.socialLink2.toLowerCase().includes("linkedin"))
        button1 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink2}>
              SUPPORT TEAM LinkedIn
            </a>
          </p>
        );
      else if (team.socialLink2.toLowerCase().includes("inst") || team.socialLink2.toLowerCase().includes("instagram"))
        button1 = (
          <p style={{ margin: 5, marginBottom: 15 }}>
            <a className={"button"} href={team.socialLink2}>
              SUPPORT TEAM Instagram
            </a>
          </p>
        );
      else
        button1 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink2}>
              SUPPORT TEAM
            </a>
          </p>
        );
      if (team.socialLink.toLowerCase().includes("fb") || team.socialLink.toLowerCase().includes("facebook"))
        button2 = (
          <p style={{ margin: 5, marginTop: 15 }}>
            <a className={"button"} href={team.socialLink}>
              SUPPORT TEAM Facebook
            </a>
          </p>
        );
      else if (team.socialLink.toLowerCase().includes("lin") || team.socialLink.toLowerCase().includes("linkedin"))
        button2 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink}>
              SUPPORT TEAM LinkedIn
            </a>
          </p>
        );
      else if (team.socialLink.toLowerCase().includes("inst") || team.socialLink.toLowerCase().includes("instagram"))
        button2 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink}>
              SUPPORT TEAM Instagram
            </a>
          </p>
        );
      else
        button2 = (
          <p style={{ margin: 5 }}>
            <a className={"button"} href={team.socialLink}>
              SUPPORT TEAM
            </a>
          </p>
        );

      return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {button1}
          {button2}
        </div>
      );
    } else if (team.socialLink)
      return (
        <a href={team.socialLink} className={"button"}>
          SUPPORT TEAM
        </a>
      );
    else return null;
  };
  console.log(team);
  return (
    <div>
      <GenericHeader setDivHeight={setDivHeight} title={team.name} backAction={() => navigate(`/${year}/teams`)} />
      <div id={"some-container"}>
        <div style={{ backgroundColor: PRIMARY }}>
          <img src={require(`../images/${path}`)} alt="Team image" height={height} style={{ alignSelf: "center", maxWidth: width, maxHeight: 200 }} />
        </div>

        <div style={{ paddingLeft: 10 }}>
          <p>{team.moto}</p>
          <p>
            {team.IEEEStudentBranch} student branch from {team.country}
          </p>
        </div>
        {team.robotName && (
          <div style={{ margin: 10 }}>
            <h5 className="headers" style={{ fontSize: 18, marginBottom: 10, textAlign: "center" }}>
              ----------Robot Info----------
            </h5>
            <p>
              Name: <b style={{ fontSize: "larger", paddingLeft: 5 }}>{team.robotName}</b>
            </p>
            {team.robotPhoto && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 50,
                    width: 100,
                    height: 120,
                    margin: 10,
                    overflow: "clip",
                    border: "1px black solid",
                  }}
                >
                  {team.robotPhoto ? (
                    <img src={require(`../images/${team.robotPhoto}`)} alt={team.robotName} width={100} height={120} style={{ alignSelf: "center", borderRadius: 50 }} />
                  ) : (
                    <img src={require(`../images/white.png`)} alt={team.robotName} width={100} height={120} style={{ alignSelf: "center", borderRadius: 50 }} />
                  )}
                </div>
                <p>{team.aboutRobot}</p>
              </div>
            )}
          </div>
        )}
        <h5 className="headers" style={{ fontSize: 18, marginBottom: 10, textAlign: "center" }}>
          ----------Team Info----------
        </h5>
        <div style={{ padding: 10, paddingBottom: 20 }}>{SupportButtons && SupportButtons()}</div>
        {Object.keys(team.members).map((m, i) => {
          const member = team.members[m];
          const path = member.photoPath;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 50,
                  width: 100,
                  height: 120,
                  margin: 10,
                  overflow: "clip",
                  border: "1px black solid",
                }}
              >
                {path ? (
                  <img src={require(`../images/${path}`)} alt={member.fullName} width={100} height={120} style={{ alignSelf: "center", borderRadius: 50, objectFit: "cover" }} />
                ) : (
                  <AccountCircleIcon
                    color={"black"}
                    fontSize={"large"}
                    sx={{
                      width: 150,
                      height: 150,
                      left: -25,
                      position: "relative",
                      top: -15,
                    }}
                  />
                )}
              </div>
              <div style={{ textAlign: "start" }}>
                <p style={{ margin: 5 }}>{member.fullName}</p>
                {m.includes("mentor") && <p style={{ margin: 5 }}>Mentor</p>}
                <p style={{ margin: 5 }}>{member.gender}</p>
                {member.age && <p style={{ margin: 5 }}>{member.age} yrs old</p>}
                <p style={{ margin: 5 }}>{member.nationality}</p>

                {member.links &&
                  member.links.map((l) => {
                    if (l.toLowerCase().includes("fb") || l.toLowerCase().includes("facebook"))
                      return (
                        <p style={{ margin: 5 }}>
                          <a href={l}>Facebook profile</a>
                        </p>
                      );
                    else if (l.toLowerCase().includes("lin") || l.toLowerCase().includes("linkedin"))
                      return (
                        <p style={{ margin: 5 }}>
                          <a href={l}>Linkedin profile</a>
                        </p>
                      );
                    else if (l.toLowerCase().includes("inst") || l.toLowerCase().includes("instagream"))
                      return (
                        <p style={{ margin: 5 }}>
                          <a href={l}>Instagram profile</a>
                        </p>
                      );
                    else
                      return (
                        <p style={{ margin: 5 }}>
                          <a href={l}>{l}</a>
                        </p>
                      );
                  })}
                <p></p>
              </div>
            </div>
          );
        })}

        {Object.keys(team.members).map((m, i) => {
          const member = team.members[m];
          if (member.origin)
            return (
              <div style={{ margin: 10 }}>
                <h5 className="headers" style={{ fontSize: 18, marginBottom: 10 }}>
                  {member.fullName}:
                </h5>
                <p>{member.origin}</p>
              </div>
            );
        })}
      </div>
    </div>
  );
}
